import { useState } from 'react'
import uniqid from 'uniqid'
import { motion } from 'framer-motion'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import './ProjectContainer.css'

const ProjectContainer = ({ project }) => {
  const imageUrl = project.image

  const [showFullScreen, setShowFullScreen] = useState(false)

  const handleImageClick = () => {
    setShowFullScreen(true)
  }

  const handleCloseFullScreen = () => {
    setShowFullScreen(false)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      className='project'
    >
      <h3>{project.name}</h3>

      <p className='project__description'>{project.description}</p>
      <div
        className='project-image'
        style={{ backgroundImage: `url(${imageUrl})` }}
        alt='Full Screen'
        onClick={handleImageClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleImageClick()
          }
        }}
        role='button' // Add a role to indicate that it's interactive
        tabIndex='0' // Add tabIndex to make it focusable
      />

      {showFullScreen && (
        <div
          className='fullscreen-overlay active'
          onClick={handleCloseFullScreen}
          onKeyDown={handleCloseFullScreen} // Add keyboard event listener
          role='button' // Add a role to indicate that it's interactive
          tabIndex='0' // Add tabIndex to make it focusable
        >
          <div className='fullscreen-image'>
            <img className='centered-image' src={imageUrl} alt='Full Screen' />
          </div>
        </div>
      )}

      {project.stack && (
        <ul className='project__stack'>
          {project.stack.map((item) => (
            <li key={uniqid()} className='project__stack-item'>
              {item}
            </li>
          ))}
        </ul>
      )}

      {project.sourceCode && (
        <a
          href={project.sourceCode}
          aria-label='source code'
          className='link link--icon'
        >
          <GitHubIcon />
        </a>
      )}

      {project.livePreview && (
        <a
          href={project.livePreview}
          aria-label='live preview'
          className='link link--icon'
        >
          <LaunchIcon />
        </a>
      )}
    </motion.div>
  )
}

export default ProjectContainer
